<template>
  <div v-if="loaded" class="print-box">
    <div class="print-left" :style="{ height: `${this.$util.getViewHeight() - 162}px` }">
      <div id="print-content" class="print-content">
        <!-- title -->
        <div class="title-box">
          <h1 class="title-main">{{ detailData.report_bill.saler_company_name }}</h1>
          <h2 class="title-sub">报价单</h2>
        </div>

        <!-- title 暂未使用 -->
        <!-- <div class="print-img">
          <img src="https://gcvip168.com/wx/pc/print-quote.png" />
        </div> -->

        <!-- formData -->
        <div class="form-box">
          <div class="form-list" v-for="(itemSt, indexSt) in formData" :key="indexSt">
            <div
              :class="{ 'form-item': true, 'form-st': !indexNd }"
              v-for="(itemNd, indexNd) in itemSt"
              :key="indexNd"
            >
              <div class="form-key">{{ itemNd.key }}{{ itemNd.key && '：' }}</div>
              <div class="form-val" v-if="itemNd.val">
                {{ detailData.report_bill[itemNd.val] || '&nbsp;' }}
              </div>
            </div>
          </div>
        </div>

        <!-- tableData -->
        <div class="table-box">
          <div class="f-bold">销售清单</div>
          <PrintTable :data="detailData.stock_list" />
        </div>

        <!-- prompt -->
        <div class="f-bold">温馨提示</div>
        <div class="prompt-box">
          <div class="prompt-left">
            <ul>
              <li>
                <span class="prompt-key">是否含运：</span>
                <span class="prompt-val">
                  {{ detailData.report_bill.has_freight ? '是' : '否' }}
                </span>
              </li>
              <li>
                <span class="prompt-key">是否含税：</span>
                <span class="prompt-val">
                  {{ detailData.report_bill.has_tax ? `含13%增值税发票` : '否' }}
                </span>
              </li>
              <li>
                <span class="prompt-key">说明：</span>
                <span class="prompt-val">
                  {{ detailData.report_bill.remark }}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="print-right">
      <div class="print-do">
        <div>
          <el-button class="print-btn" v-print="printData" type="primary" size="small">
            打印
          </el-button>
        </div>
        <div class="m-t-10">
          <el-button class="print-btn" type="default" size="small" @click="jumpEdit">
            编辑
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrintTable from './print-table'
export default {
  components: {
    PrintTable
  },
  data() {
    return {
      loaded: false,
      detailData: {},
      printData: {
        id: 'print-content',
        popTitle: '&nbsp;'
        // preview: true, // 是否开启预览
        // previewTitle: '打印预览', // 预览标题
      },
      formData: [
        [
          {
            key: '报价单号',
            val: 'bill_id'
          },
          {
            key: '供应商',
            val: 'saler_company_name'
          },
          {
            key: '联系人',
            val: 'saler'
          },
          {
            key: '手机号',
            val: 'saler_phone'
          },
          {
            key: '地址',
            val: 'saler_address'
          }
        ],
        [
          {
            key: '报价日期',
            val: 'report_time'
          },
          {
            key: '采购方',
            val: 'buyer_company_name'
          },
          {
            key: '联系人',
            val: 'buyer'
          },
          {
            key: '手机号',
            val: 'buyer_phone'
          },
          {
            key: '地址',
            val: 'buyer_address'
          }
        ]
      ]
    }
  },
  computed: {
    billId() {
      return this.$route.query.id
    }
  },
  mounted() {
    this.getDetailData()
  },
  methods: {
    // 获取详情数据
    getDetailData() {
      this.loaded = false
      this.$api({
        method: 'get',
        url: '/admin/gcvip/reportBill/detail',
        params: {
          id: this.billId
        }
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            this.detailData = this.$util.deepCopy(res.data)
          }
        })
        .catch(() => {})
        .then(() => {
          this.loaded = true
        })
    },
    // 跳转到编辑
    jumpEdit() {
      this.$router.push(`/sale/quote/detail?id=${this.billId}`)
    }
  }
}
</script>

<style lang="less" scoped>
// print
.print-box {
  display: flex;
  background: #f5f7fa;
  .print-left {
    margin: 10px;
    padding: 20px;
    width: 712px;
    background: #fff;
    overflow-y: auto;
    .scroll-bar();
  }
  .print-right {
    margin: 10px 10px 10px 0;
    padding: 10px;
    width: 200px;
    background: #fff;
    .print-btn {
      width: 100%;
    }
    .print-do {
      margin-bottom: 20px;
      padding: 20px;
      text-align: center;
      font-size: 14px;
      background: #f8f8f8;
    }
  }
}

// content
.print-content {
  font-size: 12px;
  line-height: 1.5;
  font-family: '宋体';
  overflow: hidden;
}

// title
.title-box {
  margin: 10px 0 30px 0;
  text-align: center;
  font-family: '黑体';
}
.title-main {
  margin: 0;
  font-size: 20px;
}
.title-sub {
  position: relative;
  margin: 10px 0 0 0;
  font-size: 20px;
}

// .print-img {
//   width: 100%;
//   height: 148px;
//   overflow: hidden;
// }

// form
.form-box {
  display: flex;
  margin-top: 20px;
}
.form-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  & ~ .form-list {
    margin-left: 10px;
  }
  .form-item {
    flex: 1;
    display: flex;
    align-items: center;
    &.form-st {
      font-weight: bold;
    }
    .form-key {
      border-bottom: 1px solid #fff;
    }
    .form-val {
      flex: 1;
      padding: 2px 0;
      border-bottom: 1px solid #000;
    }
  }
}

// table
.table-box {
  margin: 20px 0;
}

// prompt
.prompt-box {
  display: flex;
  margin-top: 5px;
  border: 1px solid #000;
}
.prompt-left {
  flex: 1;
  padding: 10px;
  line-height: 2;
}
.prompt-left ul {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
    display: flex;
    .prompt-key {
      display: inline-block;
    }
  }
}
.prompt-right {
  padding: 10px;
  width: 96px;
  line-height: 96px;
  text-align: center;
  border-left: 1px solid #000;
}
</style>
